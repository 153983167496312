import type { ColorType, GlobalStyleResponsiveConfig } from '../types/globalStyle';

export const getColorFormGlobalStyle = (color?: string, globalStyle?: GlobalStyleResponsiveConfig) => {
  if (!color || !globalStyle) return;
  const colorKey = color?.includes('var(--g-c-') ? color.replace('var(--g-c-', '').replace(')', '') : color;
  return globalStyle?.color?.[colorKey as ColorType] ?? color;
};

export const isTransparent = (color?: string): boolean => {
  if (!color) return false;
  if (color?.toLowerCase() === 'transparent') return true;
  const rgbaMatch = color?.toLowerCase().match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([0-9.]+))?\)/i);
  if (rgbaMatch && rgbaMatch[4]) {
    const alpha = parseFloat(rgbaMatch[4]);
    return alpha === 0;
  }

  return false;
};
