import type { UpdateSettingDataType } from '../types/unit';

export const getDataControlChange = (props: Record<string, any>): UpdateSettingDataType => {
  return {
    componentUid: props.componentUid || '',
    screenId: props.screenId,
    sectionID: props.sectionID || '',
    groupType: props.groupType || 'setting',
    componentTag: props.componentTag || '',
    controlType: props.controlType || '',
    controlId: props.controlId || '',
    hasDevices: props.hasDevices,
    devices: props.devices,
    default: props.default,
    state: props.state,
    controlChangeTrigger: props.controlChangeTrigger,
  };
};

export const isNormalState = (state?: string) => {
  return state && state === 'normal';
};
