import type { TypographyType } from '../types/globalStyle';

export const TYPOGRAPHY_STYLES: { name: string; id: TypographyType }[] = [
  { name: 'Heading 1', id: 'heading-3' },
  { name: 'Heading 2', id: 'heading-1' },
  { name: 'Heading 3', id: 'heading-2' },
  { name: 'Heading 4', id: 'subheading-3' },
  { name: 'Heading 5', id: 'subheading-1' },
  { name: 'Heading 6', id: 'subheading-2' },
  { name: 'Paragraph 1', id: 'paragraph-1' },
  { name: 'Paragraph 2', id: 'paragraph-2' },
  { name: 'Paragraph 3', id: 'paragraph-3' },
];
