export const isURL = (str: string): boolean => {
  try {
    new URL(str);
    return true;
  } catch {
    return false;
  }
};

export const extractHandleFromUrl = (url: string): string | null => {
  if (!isURL(url)) return null;

  const parsedUrl = new URL(url);
  const pathParts = parsedUrl.pathname.split('/').filter(Boolean);
  return pathParts.length ? pathParts[pathParts.length - 1] : null;
};
